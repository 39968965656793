<template>
  <div>
    <h1>ADICIONAR PRODUTOS</h1>
    <AddStock></AddStock>
  </div>
</template>
<script>
import AddStock from '@/components/AddStock.vue';
export default {
  components: {
    AddStock,
  },
};
</script>
<style scoped>
h1 {
  text-align: center;
  padding: 10px;
}
</style>
